/* You can add global styles to this file, and also import other style files */

.oswald {
    font-family: 'Oswald';
}

.roboto {
    font-family: 'Roboto';
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    font-size: 100%;
    line-height: 1.5;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: .3px;
}

.tooltip_popup {
    transform: translateX(-50%);
    font-size: 1.2vh;
    position: fixed;
    width: max-content;
    z-index: 9999;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    border-radius: 3px;
}

.tt{
    transform: translate(-50%, -100%);
}